import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import styled from 'styled-components'


const NotificationImageBox = styled.div`
  width: 100%;
  height: 128px;
  background-position: center center;
  margin: 0 0 1em;
`

class CaseStudyRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    console.log(posts);
    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-6" key={post.id}>
              <article
                className={`blog-list-item tile is-child box notification`}
              > <NotificationImageBox style={{
                backgroundImage: `url(${!!post.frontmatter.image.childImageSharp ? post.frontmatter.image.childImageSharp.fluid.src : post.frontmatter.image})`
              }}></NotificationImageBox>
                <header>
                 
                  {/* <img 
                    style ={{width: `100%`, height: `inherit`, float: `left`, margin: `0 1em 1em 0`}}
                    src={!!post.frontmatter.image.childImageSharp ? post.frontmatter.image.childImageSharp.fluid.src : post.frontmatter.image}/> */}
                  <br/>
                  <p className="post-meta">
                    <Link
                      className="title is-size-4"
                      style={{
                        textTransform: 'uppercase'
                      }}
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    {/* <span className="subtitle is-size-5 is-block">
                      {post.frontmatter.date}
                    </span> */}
                  </p>
                </header>
                <p>
                  {/* {post.excerpt} */}
                  <br />
                  <br />
                  <Link className="button" to={post.fields.slug}>
                    Get more information...
                  </Link>
                </p>
              </article>
            </div>
          ))}
      </div>
    )
  }
}

CaseStudyRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query CaseStudyRoll {
        allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "case-study"}}}) {
            edges {
              node {
                frontmatter {
                  title
                  image {
                    absolutePath
                    accessTime
                    ...FileFragment
                    childImageSharp {
                      fluid(maxWidth: 1024, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                fields {
                  slug
                }
              }
            }
          }
        }
        
        fragment FileFragment on File {
          id
        }
        
      
    `}
    render={(data, count) => <CaseStudyRoll data={data} count={count} />}
  />
)
